<template>
  <ProductsDailySuggestMB v-if="layout == 4" />
  <ProductsDailySuggestPC v-else />
</template>

<script>
import ProductsDailySuggestMB from "@/pages/mb/ProductsDailySuggest";
import ProductsDailySuggestPC from "@/pages/pc/ProductsDailySuggest";
import { provide, ref } from "vue";
import { useRoute } from "vue-router";

export default {
  name: "DailySuggest",
  components: {
    ProductsDailySuggestMB,
    ProductsDailySuggestPC,
  },
  setup() {
    const route = useRoute();
    const type = "new_arrival";

    const params = ref({
      page: 1,
      type_category: route.query.type_category ? route.query.type_category : "",
      sort_by: "random", //popularity , percent_discount
      sort_order: "desc",
      sizes: route.query.sizes ? route.query.sizes : [],
      company_ids: route.query.company_ids ? route.query.company_ids : [],
      cid: route.query.cid ? route.query.cid : [],
      color: route.query.color ? route.query.color : [],
      on_sale_from: route.query.on_sale_from ? route.query.on_sale_from : null,
      on_sale_to: route.query.on_sale_to ? route.query.on_sale_to : null,
    });

    provide("type", type);
    provide("params", params);
  },
};
</script>
